import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vexchange incentivizes users to add liquidity to trading pools by rewarding providers with the fees generated when other users trade with those pools. Market making, in general, is a complex activity. There is a risk of losing money during large and sustained movement in the underlying asset price compared to simply holding an asset.`}</p>
    <h1 {...{
      "id": "risks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#risks",
        "aria-label": "risks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Risks`}</h1>
    <p>{`To understand the risks associated with providing liquidity you can read `}<a parentName="p" {...{
        "href": "https://medium.com/@pintail/Vexchange-a-good-deal-for-liquidity-providers-104c0b6816f2"
      }}>{`https://medium.com/@pintail/Vexchange-a-good-deal-for-liquidity-providers-104c0b6816f2`}</a>{` to get an in-depth look at how to conceptualize a liquidity position.`}</p>
    <h1 {...{
      "id": "example-from-the-article",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#example-from-the-article",
        "aria-label": "example from the article permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example from the article`}</h1>
    <blockquote>
      <p>{`Consider the case where a liquidity provider adds 10,000 VTHO and 100 WVET to a pool (for a total value of `}{`$`}{`20,000), the liquidity pool is now 100,000 VTHO and 1,000 VET in total. Because the amount supplied is equal to 10% of the total liquidity, the contract mints and sends the market maker “liquidity tokens” which entitle them to 10% of the liquidity available in the pool. These are not speculative tokens to be traded. They are merely an accounting or bookkeeping tool to keep track of how much the liquidity providers are owed. If others subsequently add/withdraw coins, new liquidity tokens are minted/burned such that everyone’s relative percentage share of the liquidity pool remains the same.`}</p>
      <p><strong parentName="p">{`Now let’s assume the price trades on Coinbase from $100 to $150. The Vexchange contract should reflect this change as well after some arbitrage. Traders will add VTHO and remove VET until the new ratio is now 150:1.`}</strong></p>
      <p>{`What happens to the liquidity provider? The contract reflects something closer to 122,400 VTHO and 817 VET (to check these numbers are accurate, 122,400 `}{`*`}{` 817 = 100,000,000 (our constant product) and 122,400 / 817 = 150, our new price). Withdrawing the 10% that we are entitled to would now yield 12,240 VTHO and 81.7 VET. The total market value here is $24,500. Roughly $500 worth of profit was missed out on as a result of the market making.`}</p>
      <p><strong parentName="p">{`Obviously no one wants to provide liquidity out of charitable means, and the revenue isn’t dependent on the ability to flip out of good trades (there is no flipping). Instead, 0.3% of all trade volume is distributed proportionally to all liquidity providers. By default, these fees are put back into the liquidity pool, but can be collected any time. It’s difficult to know what the trade-off is between revenues from fees and losses from directional movements without knowing the amount of in-between trades. The more chop and back and forth, the better.`}</strong></p>
      <h2 {...{
        "id": "why-is-my-liquidity-worth-less-than-i-put-in",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#why-is-my-liquidity-worth-less-than-i-put-in",
          "aria-label": "why is my liquidity worth less than i put in permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Why is my liquidity worth less than I put in?`}</h2>
      <p>{`To understand why the value of a liquidity provider’s stake can go down despite income from fees, we need to look a bit more closely at the formula used by Vexchange to govern trading. The formula really is very simple. If we neglect trading fees, we have the following:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`eth_liquidity_pool * token_liquidity_pool = constant_product`}</inlineCode></li>
      </ul>
      <p>{`In other words, the number of tokens a trader receives for their VET and vice versa is calculated such that after the trade, the product of the two liquidity pools is the same as it was before the trade. The consequence of this formula is that for trades which are very small in value compared to the size of the liquidity pool we have:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`eth_price = token_liquidity_pool / eth_liquidity_pool`}</inlineCode></li>
      </ul>
      <p>{`Combining these two equations, we can work out the size of each liquidity pool at any given price, assuming constant total liquidity:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`eth_liquidity_pool = sqrt(constant_product / eth_price)`}</inlineCode></li>
        <li parentName="ul"><inlineCode parentName="li">{`token_liquidity_pool = sqrt(constant_product * eth_price)`}</inlineCode></li>
      </ul>
      <p>{`So let’s look at the impact of a price change on a liquidity provider. To keep things simple, let’s imagine our liquidity provider supplies 1 VET and 100 VTHO to the Vexchange VTHO exchange, giving them 1% of a liquidity pool which contains 100 VET and 10,000 VTHO. This implies a price of 1 VET = 100 VTHO. Still neglecting fees, let’s imagine that after some trading, the price has changed; 1 VET is now worth 120 VTHO. What is the new value of the liquidity provider’s stake? Plugging the numbers into the formulae above, we have:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`eth_liquidity_pool = 91.2871`}</inlineCode></li>
        <li parentName="ul"><inlineCode parentName="li">{`dai_liquidity_pool = 10954.4511`}</inlineCode></li>
      </ul>
      <p>{`“Since our liquidity provider has 1% of the liquidity tokens, this means they can now claim 0.9129 VET and 109.54 VTHO from the liquidity pool. But since VTHO is approximately equivalent to USD, we might prefer to convert the entire amount into VTHO to understand the overall impact of the price change. At the current price then, our liquidity is worth a total of 219.09 VTHO. What if the liquidity provider had just held onto their original 1 VET and 100 VTHO? Well, now we can easily see that, at the new price, the total value would be 220 VTHO. So our liquidity provider lost out by 0.91 VTHO by providing liquidity to Vexchange instead of just holding onto their initial VET and VTHO.”`}</p>
      <p>{`“Of course, if the price were to return to the same value as when the liquidity provider added their liquidity, this loss would disappear. `}<strong parentName="p">{`For this reason, we can call it an `}</strong>{`impermanent loss`}<strong parentName="p">{`.`}</strong>{` Using the equations above, we can derive a formula for the size of the impermanent loss in terms of the price ratio between when liquidity was supplied and now. We get the following:”`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`”`}<inlineCode parentName="p">{`impermanent_loss = 2 * sqrt(price_ratio) / (1+price_ratio) — 1`}</inlineCode>{`”`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`“Which we can plot out to get a general sense of the scale of the impermanent loss at different price ratios:”
`}<img parentName="p" {...{
              "src": "https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fdnazarov%2FOscQ_nmzbA.png?alt=media&token=4dff866e-a740-4121-9da4-9c9105baa404",
              "alt": null
            }}></img></p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`“Or to put it another way:”`}</p>
          <ul parentName="li">
            <li parentName="ul">{`“a 1.25x price change results in a 0.6% loss relative to HODL”`}</li>
            <li parentName="ul">{`“a 1.50x price change results in a 2.0% loss relative to HODL”`}</li>
            <li parentName="ul">{`“a 1.75x price change results in a 3.8% loss relative to HODL”`}</li>
            <li parentName="ul">{`“a 2x price change results in a 5.7% loss relative to HODL”`}</li>
            <li parentName="ul">{`“a 3x price change results in a 13.4% loss relative to HODL”`}</li>
            <li parentName="ul">{`“a 4x price change results in a 20.0% loss relative to HODL”`}</li>
            <li parentName="ul">{`“a 5x price change results in a 25.5% loss relative to HODL”`}</li>
          </ul>
        </li>
        <li parentName="ul">
          <p parentName="li">{`“N.B. The loss is the same whichever direction the price change occurs in (i.e. a doubling in price results in the same loss as a halving).” —>`}</p>
        </li>
      </ul>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      